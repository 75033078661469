@mixin __FF_SAME_ORIGIN_GRAPHQL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ADD_TO_CART_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_IN_HEAD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_ASYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_INVENTORY_VEHICLEINFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_WIPER_AVAILABILITY_FOR_STORE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GEOPOINT_DIRECTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_EMPLOYEE__DISCOUNT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_ALLOW_OPTIONAL_AND_PARTIAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INSTANT_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_IS_APPOINTMENT_STANDARD_QUOTE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_CART_ACCOUNT_DRAWER__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_MY_ACCOUNT_($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PERSIST_MULTISTORE_PLP_PRODUCT_TO_PDP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PDP_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PLP_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_TREADWELL_DATA_NONTREADWELL_PLP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_OPTIONAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_CONFIRMATION_SURVEY_INTEGRATION__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PARTYTOWN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_360_WHEEL_VIEW__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STANDARD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STAGGERED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_PREBUILT_PACKAGES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_CANONICAL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PREFETCH_APPOINTMENTS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_IMAGE_FORMATS_AUTO_SYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_RECONCILIATION_CALL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SALESFORCE_CHAT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SHOW_MOBILE_AND_ALIGNMENT_IN_STORE_DROPDOWN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TIRES_THAT_DONT_FIT_MESSAGING__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_UTAG_SYNC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WHEEL_VISUALIZER_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_AFFIRM_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_APPLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GOOGLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PAYPAL_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

.wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid $grey-200;
  background: $white;
  z-index: 10;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  margin: 0 auto;
  padding: 0 20px;
  max-width: $content-max-width-xl;

  @include media($min-sm) {
    padding: 0 40px;
    height: 64px;
    margin: 14px auto;
  }

  @include media($min-md) {
    height: 74px;
    margin: 0 auto;
  }

  @include media($min-lg) {
    height: 87px;
  }

  @include media($min-xl) {
    padding: 0;
  }
}

.site-logo {
  max-width: 75px;
  vertical-align: middle;

  @include media($min-md) {
    max-width: 120px;
  }

  @include media($min-lg) {
    max-width: 150px;
  }
}

.need-help {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  background: $white;
  color: $grey-700;
}

.icon {
  margin-left: 16px;
  font-size: rem(18);
  color: $medium-light-gray;
  pointer-events: none;
}

.list {
  font-size: rem(14);
  text-align: center;
}

.list-item {
  border-bottom: 1px solid $dt-medium-light-gray;

  &:last-child {
    border: 0;
  }
}

.help-option {
  display: block;
  padding: 19px;
}

.help-option-icon {
  margin-right: 12px;
  color: $dark-gray;
}

.need-help-popover {
  border: 1px solid $medium-light-gray;
  width: 157px;
  :global {
    .popover__pointer {
      border: 1px solid $medium-light-gray;
      background: $white;
    }
    .popover__child-wrapper {
      background: $white;
    }
  }
}
